import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import { SEO } from "../components/seo";

const NotFoundPage = ({ location }) => {
  const { site } = useStaticQuery(graphql`
    query PageNotFoundQuery {
      site {
        siteMetadata {
          title
          companyNumber
          companyEmail
        }
      }
    }
  `);
  return (
    <Layout location={location} site={site}>
      <SEO title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... We apologize for the inconvience.</p>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.string.isRequired
};

NotFoundPage.defaultPropTypes = {
  location: {}
};

export default NotFoundPage;
